import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Auth from './auth'
import Content from './Content'

import css from '../App.scss'

const Index = () => {
  const authState = useSelector(state => state.auth);

  if(authState.isAuthenticated === false ){
    return <Auth />
  }
  if(authState.isAuthenticated === true ){
    return (
        <Content />
    )
  }

}

export default Index